let _inlineStyleAdded = false;
function addInlineStyle(){
	if( _inlineStyleAdded ){
		return;
	}
	_inlineStyleAdded = true;
	const head = document.querySelector('head');
	const style = document.createElement('style');
	head.appendChild( style );
	style.type="text/css";
	style.innerHTML = `
	iframe.--animate {
		transition: 0.2s height;
		will-change: height;
	}
	`;
}

class IframeWatcher{

	constructor(iframe){
		this.iframe = iframe;
		window.addEventListener('scroll', e => this.update(), false );
		window.addEventListener('resize', e => this.update(), false );
		window.addEventListener('orientationchange', e => this.update(), false );
		this.update();
	}

	update(){
		const message = {
			cmd: 'dimensions',
			dimensions: {
				width: window.innerWidth,
				height: window.innerHeight,
				scrollX: window.scrollX,
				scrollY: window.scrollY
			}
		};
		this.iframe._boundingClientRect = this.iframe.getBoundingClientRect()
		this.iframe.contentWindow.postMessage(message, '*');
	}
}

function init(){

	try {
		throw new Error();
	}catch(e){
		let matches = e.stack.match(/(https?\:\/\/[^\s]+\.js)/);
		if( !matches ){
			return;
		}

		const src = matches[1];


		const a = document.createElement('a');
		a.setAttribute( 'href', src );
		const origin = a.protocol+'//'+a.hostname+(a.port && !~['80','443',80,443].indexOf(a.port)?(':'+a.port):'');

		// path
		const baseurl = src.replace(/\/[^\/]+$/, '/');

		const charts = document.querySelectorAll('[data-freddiemac-chart]');
		const iframes = {};

		let i = 0;
		for( i=0; i<charts.length; i++){
			let container = charts[i];
			if( container.querySelector('iframe') ){
				continue;
			}
			// we are going to embed an iframe in this container
			let iframe = document.createElement( 'iframe' );
			let type = container.getAttribute( 'data-freddiemac-chart' );

			let params = [];
			let attrs = [].filter.call(container.attributes, function(at){return /^data-/.test(at.name);});
			let j = 0;
			for( j=0; j<attrs.length; j++){
				let attr = attrs[j];
				let key = attr.name.replace(/^data\-/, '').replace(/-(\w)/, (m) => m[1].toUpperCase() );
				params.push( key +'='+ encodeURIComponent( attr.value ) );
			}
			var id = crypto.randomUUID();
			params.push('_id='+id );
			
			iframe.setAttribute( 'src', baseurl+type+'/embed.html?'+params.join('&') );
			iframe.setAttribute( 'scrolling', 'no' );
			iframe.setAttribute( 'style', 'width:1px;min-width: 100%;border: 0;height:300px;display: block;' );

			container.appendChild( iframe );

			iframe._boundingClientRect = iframe.getBoundingClientRect()
			iframes[id] = iframe;
			addInlineStyle();
		}

		window.addEventListener( 'message', function( e ){
			if( origin !== e.origin ){
				return;
			}
			let data = e.data;

			if( !data || !data.id || !iframes[data.id] ){
				return;
			}

			let iframe = iframes[data.id];

			if( data.event == 'height' && data.data.height ){
				iframe.classList.add('--animate');
				iframe.addEventListener( 'transitionend', function(){
					this.classList.remove('--animate');
				}, {once: true});
				iframe.style.height = data.data.height+'px';
			}

			else if( data.event == 'scroll' && data.data.scrollTo > -1 ){
				const rect = iframe.getBoundingClientRect();
				const top = data.data.scrollTo + rect.top + (typeof window.scrollY === 'undefined' ? window.pageYOffset : window.scrollY);
				const behavior = 'smooth';
				window.scrollTo({top, behavior});
			}

			else if( data.event == 'watchDimensions' ){
				new IframeWatcher(iframe);
			}

		});

		function mouseHandler(e){
			var i = null;
			for( i in iframes ){
				const iframe = iframes[i];
				if( !iframe._boundingClientRect ){
					iframe._boundingClientRect = iframe.getBoundingClientRect();
				}
				iframe.contentWindow.postMessage({
					cmd: e.type,
					event: {
						pageX: e.pageX,
						pageY: e.pageY,
						scrollX: window.scrollX,
						scrollY: window.scrollY,
					},
					boundingClientRect: iframe._boundingClientRect,
					x: iframe._boundingClientRect.x + window.scrollX,
					y: iframe._boundingClientRect.y + window.scrollY
				}, '*');
			}
		}

		window.addEventListener( 'mouseup', mouseHandler, false);
		window.addEventListener( 'mousedown', mouseHandler, false);
		window.addEventListener( 'mousemove', mouseHandler, false);
	}
}

if( document.readyState === 'complete' ){
	init();
}
else {
	document.addEventListener( 'DOMContentLoaded', init );
}
